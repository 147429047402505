<template>
  <div class="chart-container">
    <div
      class="chart-wrapper"
      style="position: relative; width: 100%;"
      v-bind:style="{ height: height }">

      <div v-if="!hasdata" class="sp-no-chart-data">
        <div class="sp-chart-no-data-text">
          No Data
        </div>
      </div>
      <canvas class="chart-holder"></canvas>
    </div>
    <div class="alert alert-warning col-sm-4">
      <i class="fa fa-warning"></i> No Data
    </div>
    <div class="no-print pull-right">
      <button :disabled="!hasdata" type="button" class="btn btn-primary btn-info" @click="download">Download</button>      
      <a v-if="moreInfoLink" :href="moreInfoLink" target="_blank" 
      class="btn btn-primary btn-info ml-2">Details&nbsp;&nbsp;<span class="fa fa-chevron-right"></span></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    device: Object, // a device or a property object
    config: Object, // chart config object, must have .sensors (config per graph)
    series: Array, // optional, string array of keys to match with config.sensors
    signal: String, // optional, delay initial fetch and plot until Vue.eventBus emits signal
    height: { type: String, required: false, default: "300px" },
    moreInfoLink: {type: String, required: false}
  },
  methods: {
    download: function() {
      var vm = this;
      var csvLines = [];
      let datasetName = "";
      if (vm.config.graphGroup) {
        datasetName = vm.config.graphGroup;
      }

      if (vm.hasdata) {
        var getLeadingZeroStr = function(intValue) {
          var str =
            intValue >= 10.0 ? intValue.toString() : "0" + intValue.toString();
          return str;
        };

        var propNames = Object.getOwnPropertyNames(vm.data);
        csvLines.push(propNames.join(",")); //add the headers
        var rowCount = vm.data["time"].length;

        for (var rowIx = 0; rowIx < rowCount; rowIx++) {
          var line = [];
          for (const propName of propNames) {
            if (propName == "time") {
              var dateTime = new Date(vm.data[propName][rowIx]);
              let formattedDate =
                dateTime.getFullYear() +
                "-" +
                getLeadingZeroStr(dateTime.getMonth() + 1) +
                "-" +
                getLeadingZeroStr(dateTime.getDate()) +
                " " +
                getLeadingZeroStr(dateTime.getHours()) +
                ":" +
                getLeadingZeroStr(dateTime.getMinutes()) +
                ":" +
                getLeadingZeroStr(dateTime.getSeconds());
              line.push(formattedDate);
            } else {
              line.push(vm.data[propName]["data"][rowIx]);
              if (datasetName.length === 0) {
                datasetName = propName;
              }
            }
          }
          csvLines.push(line.join(","));
        }

        let csvContent = "data:text/csv;charset=utf-8,\n" + csvLines.join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);

        var idStr = vm.device.dev_eui || vm.device._id; //because 'device' can be a property
        const fileName = idStr + " - " + datasetName + ".csv";
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for FF
        link.click();
      }
    },
    plot: function(load) {
      // to update the chart... (check the 'watch' section below)
      // - set config.reading to match an attribute/key in config.sensors
      // - set the config.graphGroup to a string name matching
      //        one or more .group values in config.sensors (multiple graphs on one chart)
      // - set the config.series to an array of attributes/keys in config.sensors (deprecated, set .graphGroup)
      // - also setting the dates or config.period

      var vm = this;
      var canvas = $(vm.$el).find("canvas")[0];

      var graphConfigs = null;
      if (vm.config.graphGroup) {
        // loop through vm.config.sensors and return sensors with a matching group string
        var graphGroup = vm.config.graphGroup.toString();
        var groupGraphs = _.pickBy(
          vm.config.sensors,
          (graph) => graph.group === graphGroup
        );
        graphConfigs = groupGraphs;
      }

      if (!graphConfigs && vm.series) {
        var seriesGraphs = _.pick(vm.config.sensors, vm.series);
        graphConfigs = seriesGraphs;
      }

      if (!graphConfigs && vm.config.series) {
        var configSeriesGraphs = _.pick(vm.config.sensors, vm.config.series);
        graphConfigs = configSeriesGraphs;
      }

      if (!graphConfigs) {
        graphConfigs = vm.config.sensors; // all sensors
      }

      if (load === false) {
        vm.hasdata = plotChart(
          vm.data,
          vm.device,
          vm.config,
          graphConfigs,
          canvas,
          vm.cid
        );
      } else {
        fetch(vm.device, vm.config, graphConfigs, vm.fetchTS)
          .then(function(data) {
            vm.data = data;
            var hd = plotChart(
              vm.data,
              vm.device,
              vm.config,
              graphConfigs,
              canvas,
              vm.cid
            );
            Vue.set(vm, "hasdata", hd);
          })
          .catch(function(resp) {
            console.error(resp);
            vm.data = [];
            Vue.set(vm, "hasdata", false);
          });
      }
    },
    init: function() {
      var vm = this;
      if (vm.didLoad) {
        return;
      }
      vm.plot();
      vm.didLoad = true;
    },
  },
  data: function() {
    return {
      didLoad: false,
      hasdata: true,
      localConfig: {},
    };
  },
  watch: {
    "config.period": function(newVal, oldVal) {
      this.plot();
    },
    "config.dateRangeCfg.start_date": function(newVal, oldVal) {
      this.plot();
    },
    "config.dateRangeCfg.end_date": function(newVal, oldVal) {
      this.plot();
    },
    "config.sensors": function(newVal, oldVal) {
      this.plot(false);
    },
    "config.series": function(newSeriesArray, oldSeriesArray) {
      if (!_.isEqual(newSeriesArray, oldSeriesArray)) {
        this.plot(false);
      }
    },
    "config.reading": function(newVal, oldVal) {
      this.plot();
    },
    "config.graphGroup": function(newVal, oldVal) {
      this.plot();
    },
  },
  created: function() {
    var vm = this;
    if (vm.signal) {
      Vue.eventBus.$on(vm.signal, vm.init);
    }
  },
  mounted: function() {
    var vm = this;
    if (vm.config.urls) {
      // them scheme mode
      //vm.localConfig.urls = window.SMARTPLACES.urls[vm.config.urls].get_for(vm.device)
      //console.log("Local Config: " + vm.localConfig.urls.feed_timeseries)
      vm.fetcher = window.ScCommon.dataClient.for(
        vm.config.urls,
        vm.device._id
      );
    }

    // setup fetch for common config
    if (!vm.config.fetchTS) {
      vm.fetchTS = function(id, mode, arg, from, to, reading, graphGroup) {
        // period could be a period descriptor [6h, 24h, 7d etc] in which case the from and to args are not used
        // or it could be a fetching mode 'day', 'week', 'month' etc with a from and to arg to specify the date range
        if (mode == "range") {
          // range query
          var params = {
            mode: arg,
            from: from,
            to: to,
          };

          if (reading) {
            params.reading = reading;
          }

          if (graphGroup) {
            params.graphGroup = graphGroup;
          }

          return vm.fetcher.timeseries(params);
        } else {
          return vm.fetcher.timeseries({ period: arg });
        }
      };
    } else {
      vm.fetchTS = vm.config.fetchTS;
    }

    if (!vm.signal) {
      console.log("sc-device-chart:  vm.init()");
      vm.init();
    }
  },
};

function makeChartLink(propertyId, type, deviceId, toTab) {
    var vm = this; // Vue instance, and uses the Tab Mixin

    var params = vm.$root.addTabToFromBackParams(null,
        toTab); // use the $root components as it is the one with the tab mixin
    return urls.room_detail_with_args({
        device_id: deviceId,
        model: type
    }) + '?' + params.toString()
}

function fetch(device, config, graphConfigs, fetchTS) {
  // maybe try a plot hint to suggest what's changed in order to support both period and range modes

  if (config.dateRangeCfg) {
    // then were using date range mode
    var cfg = config.dateRangeCfg;
    var reading = config.reading ? config.reading : null; // optional reading
    var graphGroup = config.graphGroup ? config.graphGroup : null; // optional graphGroup
    return fetchTS(
      device._id,
      "range",
      cfg.mode,
      moment(cfg.start_date).toISOString(),
      moment(cfg.end_date).toISOString(),
      reading,
      graphGroup
    ).then(function(data) {
      return CHARTING.organiseAllSeries(data, graphConfigs); // re-shape influx to chartJS format
    });
  } else {
    return fetchTS(device._id, "period", config.period).then(function(data) {
      return CHARTING.organiseAllSeries(data, graphConfigs); // re-shape influx to chartJS format
    });
  }
}



function plotChart(data, device, config, graphConfigs, element, chartId) {
  var ctx = element.getContext("2d");
  var chartCfg = {
    type: "bar",
    data: {
      labels: data.time,
      datasets: [],
    },
    options: {
      elements: {
        points: {
          radius: 10,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              tooltipFormat: "ll HH:mm",
              displayFormats: {
                hour: "MMM D h:mm a",
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Date & Time",
            },
            categoryPercentage: 0.5,
            barPercentage: 0.7,
            ticks: {
              minRotation: 45,
            },
          },
        ],
        yAxes: [],
      },
      annotation: {
        drawTime: "beforeDatasetsDraw",
        events: null,
        annotations: [],
      },
    },
  };

  var graphKeys = [];
  /* TODO: replace the need for the later translation */
  // todo: switch this 'if' around so that the default does not need new schemes adding
  //       and the 'else' is the exception (as this is frozen and will not change)
  if (
    device.scheme &&
    _.includes(
      [
        "flood",
        "gullies",
        "rain",
        "road",
        "waste",
        "housing",
        "aq",
        "maint",
        "smartbuilding",
        "carpark",
        "footfall",
        "traffic",
      ],
      device.scheme
    )
  ) {
    graphKeys = _.keys(graphConfigs);

    graphKeys = _.filter(graphKeys, function(graphKey) {
      if (graphKey.startsWith("event:")) {
        return true;
      }

      if (device.scheme == "aq") {
        return (
          graphKey == "aqi" ||
          _.includes(device.fields, graphKey) ||
          _.includes(device.fields, graphKey.replace("_aqi", ""))
        );
      } else {
        return _.includes(device.fields, graphKey);
      }
    });
  } else {
    graphKeys = SMART_PROPERTY.getDeviceSensors(_.keys(graphConfigs), device);
  }

  var graphDataMinMax = {};
  var graphGroupMinMax = {};

  // go through each graph's data and gather min max
  // update the group's min max per unit
  // - so that graphs in the same group with the same units have the same data ranges.
  graphKeys.forEach(function(graphKey) {
    if (!_.has(data, graphKey) || data[graphKey].data.length == 0) {
      return;
    }

    var graphConfig = graphConfigs[graphKey];
    graphDataMinMax[graphKey] = {
      dataMin: data[graphKey].min,
      dataMax: data[graphKey].max,
    };
    var gdRange = graphDataMinMax[graphKey];

    if (graphConfig.ticks) {
      var ticks = graphConfig.ticks;
      if (typeof ticks == "object") {
        if (ticks.beginAtZero === true || "suggestedMin" in ticks) {
          var suggestedMin =
            ticks.beginAtZero === true ? 0 : ticks.suggestedMin;

          gdRange.dataMin = Math.min(suggestedMin, gdRange.dataMin);
        }

        if ("suggestedMax" in ticks) {
          gdRange.dataMax = Math.max(ticks.suggestedMax, gdRange.dataMax);
        }
      }
    }

    var groupUnitsKey = "";
    if (graphConfig.group) {
      groupUnitsKey = graphConfig.group + ":[" + graphConfig.unit + "]";
      if (graphGroupMinMax[groupUnitsKey]) {
        graphGroupMinMax[groupUnitsKey].groupMin = Math.min(
          gdRange.dataMin,
          graphGroupMinMax[groupUnitsKey].groupMin
        );
        graphGroupMinMax[groupUnitsKey].groupMax = Math.max(
          gdRange.dataMax,
          graphGroupMinMax[groupUnitsKey].groupMax
        );
      } else {
        graphGroupMinMax[groupUnitsKey] = {
          groupMin: gdRange.dataMin,
          groupMax: gdRange.dataMax,
        };
      }
    }
  });

  var hasdata = false;
  graphKeys.forEach(function(graphKey) {
    var sensor = graphConfigs[graphKey];

    if (!_.has(data, graphKey) || data[graphKey].data.length == 0) {
      return;
    }

    hasdata = true;
    var graphData = data[graphKey].data;

    var groupUnitsKey = "";
    if (sensor.group) {
      groupUnitsKey = sensor.group + ":[" + sensor.unit + "]";
    }

    var sensorCfg = {
      type: sensor.chartType,
      label: sensor.name,
      data: graphData,
      borderColor: sensor.color,
      backgroundColor: sensor.bg,
      fill: sensor.chartType == "bar",
      yAxisID: "y-axis-" + graphKey,
    };

    if (!sensor.display) {
      sensorCfg.cubicInterpolationMode = "monotone";
    } else if (sensor.display == "stairs2-nopt") {
      sensorCfg.steppedLine = "before";
      sensorCfg.lineTension = 0;
      sensorCfg.pointRadius = 0.5;
      sensorCfg.borderWidth = 2;
    } else if (sensor.display == "straight2-nopt") {
      (sensorCfg.steppedLine = null), (sensorCfg.lineTension = 0);
      sensorCfg.pointRadius = 0.5;
      sensorCfg.borderWidth = 2;
    } else if (sensor.display == "points") {
      (sensorCfg.steppedLine = null), (sensorCfg.lineTension = 0);
      sensorCfg.pointRadius = 10;
      sensorCfg.pointHoverRadius = 13;
      sensorCfg.borderWidth = 1;
      sensorCfg.showLine = false;
      sensorCfg.pointStyle = "triangle";
    } else {
      console.log("Don't know display mode: " + sensor.display);
      sensorCfg.cubicInterpolationMode = "monotone";
    }

    chartCfg.data.datasets.push(sensorCfg);

    if (sensor.position == "left" || sensor.position == "right") {
      var cfg = {
        type: "linear",
        scaleLabel: {
          display: true,
          labelString: sensor.name + " " + sensor.unit,
        },
        id: "y-axis-" + graphKey,
        position: sensor.position,
        gridLines: {
          display: !!sensor.grid,
          color: "#cccccc ",
          drawOnChartArea: sensor.grid, // only want the grid lines for one axis to show up
        },
      };

      /**
       * device override of max/min takes precedence,
       * then auto-ranging, then fixed in series config of module
       */
      if (sensor.ticks) {
        var field = window.SMART_PROPERTY.mapKeyAndField(graphKey) || graphKey;

        var min = _.get(device, "ranges." + field + ".min");
        if (!min) {
          if (
            sensor.ticks == "auto" ||
            sensor.ticks.min == "auto" ||
            sensor.ticks.matchGroupMinMax === true
          ) {
            if (groupUnitsKey) {
              min = graphGroupMinMax[groupUnitsKey].groupMin;
            } else {
              min = data[graphKey].min;
            }
          } else if (
            typeof sensor.ticks.min === "string" &&
            sensor.ticks.min.indexOf("<") == 0
          ) {
            var atLeastMin = Number.parseInt(sensor.ticks.min.substring(1));
            min = Math.min(data[graphKey].min, atLeastMin);
          } else {
            min = sensor.ticks.min;
          }
        }

        var max = _.get(device, "ranges." + field + ".max");
        if (!max) {
          if (
            sensor.ticks == "auto" ||
            sensor.ticks.max == "auto" ||
            sensor.ticks.matchGroupMinMax === true
          ) {
            if (groupUnitsKey) {
              max = graphGroupMinMax[groupUnitsKey].groupMax;
            } else {
              max = data[graphKey].max;
            }
          } else if (
            typeof sensor.ticks.max === "string" &&
            sensor.ticks.max.indexOf(">") == 0
          ) {
            var atLeastMax = Number.parseInt(sensor.ticks.max.substring(1));
            max = Math.max(data[graphKey].max, atLeastMax);
          } else {
            max = sensor.ticks.max;
          }
        }

        cfg.ticks = {
          min: min,
          max: max,
        };
      }

      chartCfg.options.scales.yAxes.push(cfg);
    } else if (sensor.position == "hidden") {
      chartCfg.options.scales.yAxes.push({
        id: "y-axis-" + graphKey,
        display: false,
        ticks: { min: 0, max: 20 },
      });
    }

    if (!!config.thresholds && sensor.thresholds && sensor.thresholds.length) {
      _.each(sensor.thresholds, function(th_cfg) {
        var th = {
          type: "box",
          borderWidth: 0,
          borderColor: "white",
        };

        th.yScaleID = "y-axis-" + graphKey;
        if (th_cfg.min) {
          th.yMin = th_cfg.min;
        }
        if (th_cfg.max) {
          th.yMax = th_cfg.max;
        }
        th.backgroundColor = th_cfg.color;

        chartCfg.options.annotation.annotations.push(th);
      });
    }
  });

  config.charts = config.charts || { ts: {}, alarms: {} };
  if (config.charts.ts[chartId]) {
    config.charts.ts[chartId].destroy();
  }

  config.charts.ts[chartId] = new Chart(ctx, chartCfg);

  return hasdata;
}
</script>

<style>
.chart-container {
  margin-bottom: 50px;
}
.chart-container.nodata .chart-wrapper {
  display: none;
}
.chart-container .alert {
  display: none;
}
.chart-container.nodata .alert {
  display: block;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
